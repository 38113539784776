import { useQuery } from '@tanstack/react-query';
import { locationClient } from '../clients/locationClient';

import { cacheKeys } from '../config';


type Options = {
  enabled: boolean;
  suspense: boolean,
};

const defaultOptions: Partial<Options> = {
  enabled: true,
  suspense: true,
};

export const useAddressSearch = (q: string, options: Partial<Options> = defaultOptions) => {
  const { data: { data } = {}, status, error } = useQuery(
    [cacheKeys.searchAddress, q],
    () => locationClient.searchAddress(q),
    {
      ...options,
      enabled: !!q && options.enabled,
    },
  );

  return {
    status,
    error,
    results: data || [],
  };
};
