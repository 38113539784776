import { useQuery } from '@tanstack/react-query';

import { cacheKeys } from '../config';
import { locationClient } from '../clients/locationClient';

type Options = {
  enabled: boolean
  suspense: boolean
};

const defaultOptions: Partial<Options> = {
  enabled: true,
  suspense: true,
};

export const useIpGeoLocation = (options: Partial<Options> = defaultOptions) => {
  const { data: { data: location } = {}, status, error } = useQuery(
    [cacheKeys.getIpLocation],
    () => locationClient.getIpAddressGeoLocation(),
    options,
  );

  return {
    status,
    error,
    location,
  };
};
