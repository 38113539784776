import en from './languages/en';
import de from './languages/de';
import { routes } from './routes';
import { isBeta, isIos } from './helpers/environment';

const config: CVT.Config = {
  projectName: 'THR',
  featureFlags: {
    appleSSO: isIos() && isBeta,
    phoneLogin: true,
    googleSSO: true,
    microsoftSSO: false,
    facebookSSO: true,
    translations: false,
    lightMode: false,
    communityContacts: false,
  },
  theme: {
    defaultMode: 'dark',
    drawerWidth: 240,
    disableRipple: true,
    elevation: 0,
  },
  language: {
    dictionaries: {
      en,
      de,
    },
    languageLabelMap: {
      en: 'English',
      de: 'Deutsche',
    },
    supportedLanguages: ['en', 'de'],
    defaultLanguage: 'en',
    defaultDictionary: en,
  },
  defaultPermissions: {
    users: {
      list: false,
      view: true,
      create: false,
      edit: false,
      delete: false,
      invite: true,
      tracking: false,
      pro: false,
    },
    connections: {
      list: true,
      view: true,
      create: false,
      edit: true,
      delete: false,
    },
    trips: {
      list: true,
      view: true,
      create: false,
      edit: false,
      delete: false,
    },
    contacts: {
      list: true,
      view: true,
      create: false,
      edit: false,
      delete: false,
      import: false,
    },
    communities: {
      list: true,
      view: true,
      create: false,
      edit: false,
      delete: false,
    },
    events: {
      list: true,
      view: true,
      create: false,
      edit: false,
      delete: false,
    },
    messaging: {
      list: true,
      view: true,
      create: false,
      edit: false,
      delete: false,
    },
  },
  routes: routes,
  dialogs: [
    'nativeMobileApp',
    'search',
    'createTrip',
    'editTrip',
    'createContact',
    'editContact',
    'viewContact',
    'createCommunity',
    'editCommunity',
    'inviteCommunityMembers',
    'viewCommunity',
    'createCommunityChannel',
    'editCommunityChannel',
    'inviteCommunityChannelMembers',
    'createEvent',
    'editEvent',
    'createChat',
    'viewPicture',
    'confirmDeleteProfile',
    'userQrCode',
    'viewUser',
    'userChat',
    'tripNotificationEdit',
    'eventView',
  ],
};

export const googleMapsApiKey =  import.meta.env.VITE__CVT_GOOGLE_MAPS_KEY;

export default config;
