import * as React from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { Badge, BottomNavigation, BottomNavigationAction, useTheme } from '@mui/material';
import { HomeTwoTone, GroupsTwoTone, SupervisedUserCircleTwoTone, PersonTwoTone, MapTwoTone } from '@mui/icons-material';

import { RoutingContext } from '@cvt/contexts';

import { isNative } from '@shared/helpers/environment';
import { UserMenu } from '@shared/components/Header/UserMenu';
import useNetworkSearchParams from '@shared/hooks/useNetworkSearchParams';
import { LayoutContext } from '@shared/contexts/LayoutContext/LayoutContext';

import { AuthContext } from '@modules/Auth/contexts';
import { UserContext } from '@modules/Users/contexts';
import { useNotifications } from '@modules/Notifications/hooks/useNotifications';

export const MobileNavigation: React.FC = () => {
  const { router } = React.useContext(RoutingContext);
  const { isMobile } = React.useContext(LayoutContext);
  const { unreadMessages } = React.useContext(UserContext);
  const { isLoggedIn } = React.useContext(AuthContext);

  const [isUserMenuOpen, setIsUserMenuOpen] = React.useState(false);

  const location = useLocation();
  const theme = useTheme();
  const networkSearchParams = useNetworkSearchParams();

  const { notifications } = useNotifications({ limit: 20 }, {
    enabled: isLoggedIn,
  });
  
  const hasUserUnreadEntities = React.useMemo(() => {
    // Direct messages, community/network messages, unread notifications
    return !unreadMessages.direct.length && !unreadMessages.communities.length && !notifications.filter(notification => !notification.readAt).length;
  }, [notifications, unreadMessages]);

  const handleOpenUserMenu = () => {
    setIsUserMenuOpen(true);
  };

  const handleCloseUserMenu = () => {
    setIsUserMenuOpen(false);
  };

  const pathname = React.useMemo(() => {
    const searchPaths = [
      router.search.path,
      router.search.tab().path,
    ];
    const networkPaths = [
      router.spheres.path,
      router.spheres.tab().path,
    ];

    if (searchPaths.some(path => matchPath(path, location.pathname))) {
      return router.search.path;
    }
    if (networkPaths.some(path => matchPath(path, location.pathname))) {
      return router.spheres.path;
    }

    return location.pathname;
  }, [router, location.pathname]);

  const shouldHideNavigation = React.useMemo(() => {
    const pathsToIgnore = [
      router.communities.view().details.path,
      router.communities.edit().path,
    ];

    // Fix: Some paths match bellow, so we ignore them here
    if (pathsToIgnore.some(path => matchPath(path, location.pathname))) {
      return false;
    }

    const paths = [
      router.onboarding.path,
      router.onboarding.step().path,
      router.communities.view().general.path,
      router.communities.view().channel().path,
      router.inbox.view().path,
    ];

    return paths.some(path => matchPath(path, location.pathname));
  }, [router, location]);

  if (shouldHideNavigation) {
    return null;
  }

  return (
    <React.Fragment>
      {isMobile && (
        <BottomNavigation
          value={pathname}
          showLabels
          sx={{
            borderTopWidth: 1,
            borderTopStyle: 'solid',
            zIndex: theme.zIndex.speedDial,
            borderColor: theme.palette.divider,
            pt: 1,
            pb: isNative() ? 'env(safe-area-inset-bottom)' : theme.spacing(1.5),
            background: 'transparent',
          }}
        >
          <BottomNavigationAction
            label="Home"
            icon={<HomeTwoTone/>}
            component={Link}
            to={{
              pathname: router.home.path,
              search: networkSearchParams,
            }}
            value={router.home.path}
          />
          <BottomNavigationAction
            label="Connections"
            icon={<GroupsTwoTone/>}
            component={Link}
            to={router.spheres.tab('connections').path}
            value={router.spheres.path}
          />
          <BottomNavigationAction
            label="Map"
            icon={<MapTwoTone/>}
            component={Link}
            to={router.users.map.path}
            value={router.users.map.path}
          />
          {/* // TODO: Delete or uncomment if we need dialog for quick actions (create trip, create community etc.)
          <Box width={56} flexShrink={0} flexGrow={0} /> */}
          {/*<BottomNavigationAction*/}
          {/*  label="Events"*/}
          {/*  icon={<ConfirmationNumberTwoTone/>}*/}
          {/*  component={Link}*/}
          {/*  to={router.events.path}*/}
          {/*  value={router.events.path}*/}
          {/*/>*/}
          <BottomNavigationAction
            label="Networks"
            icon={(
              <Badge badgeContent={unreadMessages.communities.length} color="error">
                <SupervisedUserCircleTwoTone/>
              </Badge>
            )}
            component={Link}
            to={router.network.path}
            value={router.network.path}
          />
          <BottomNavigationAction
            label="You"
            icon={(
              <Badge variant="dot" invisible={hasUserUnreadEntities} color="error">
                <PersonTwoTone/>
              </Badge>
            )}
            onClick={handleOpenUserMenu}
          />
        </BottomNavigation>
      )}
      <UserMenu isOpen={isUserMenuOpen} onCloseRequest={handleCloseUserMenu}/>
      {/* {isMobile ? (
        <React.Fragment>
          <Box
            position="fixed"
            zIndex={theme => theme.zIndex.appBar}
            bottom={theme => `calc(env(safe-area-inset-bottom) + ${theme.spacing(2)})`}
            left={isMobile ? '50%' : 'auto'}
            right={isMobile ? 'auto' : 16}
            sx={{
              transform: isMobile ? 'translateX(-50%)' : undefined,
            }}
          >
            <Box
              component="button"
              onClick={toggleCreate}
              sx={theme => ({
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: 1,
                width: 56,
                height: 56,
                backgroundColor: 'secondary.main',
                color: 'common.white',
                borderRadius: '50%',
                border: 'none',
                boxShadow: theme.shadows[6],
              })}>
              <SpeedDialIcon />
            </Box>
          </Box>
          <Drawer
            open={createOpen}
            onClose={toggleCreate}
            anchor="bottom"
            ModalProps={{
              sx: theme => ({
                zIndex: theme.zIndex.drawer - 1,
              }),
            }}
            PaperProps={{
              sx: theme => ({
                left: theme.spacing(1.5),
                right: theme.spacing(1.5),
                bottom: isNative() ? 'env(safe-area-inset-bottom)' : theme.spacing(1.5),
                borderRadius: theme.shape.borderRadius,
                maxWidth: theme.breakpoints.values.sm,
                margin: 'auto',
              }),
            }}
          >
            <List>
              <ListItem>
                <ListItemButton onClick={() => [openDialog('createTrip', { replace: true }), toggleCreate()]}>
                  <ListItemIcon><Flight color="secondary" /></ListItemIcon>
                  <ListItemText primary="Trip"/>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={() => [openDialog('createChat', { replace: true }), toggleCreate()]}>
                  <ListItemIcon><Telegram color="secondary" /></ListItemIcon>
                  <ListItemText primary="Message"/>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={() => [openDialog('createContact', { replace: true }), toggleCreate()]}>
                  <ListItemIcon><Person color="secondary" /></ListItemIcon>
                  <ListItemText primary="Contact"/>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton onClick={() => [openDialog('createCommunity', { replace: true }), toggleCreate()]}>
                  <ListItemIcon><Groups color="secondary" /></ListItemIcon>
                  <ListItemText primary="Community"/>
                </ListItemButton>
              </ListItem>
            </List>
          </Drawer>
        </React.Fragment>
      ) : (
        <Box>
          <SpeedDial
            ariaLabel="Menu"
            FabProps={{ color: theme.palette.mode === 'light' ? 'secondary' : 'primary' }}
            icon={<SpeedDialIcon sx={{ '& svg': { fill: '#fff' } }}/>}
            sx={{ position: 'fixed', bottom: 'calc(env(safe-area-inset-bottom) + 16px)', right: isMobile ? 'auto' : 16, left: isMobile ? '50%' : 'auto', transform: isMobile ? 'translateX(-50%)' : undefined }}
          >
            <SpeedDialAction icon={<Groups/>} onClick={() => openDialog('createCommunity', { replace: true })} tooltipTitle="Community" tooltipOpen/>
            <SpeedDialAction icon={<Telegram/>} onClick={() => openDialog('createChat', { replace: true })} tooltipTitle="Message" tooltipOpen/>
            <SpeedDialAction icon={<Person/>} onClick={() => openDialog('createContact', { replace: true })} tooltipTitle="Contact" tooltipOpen/>
            <SpeedDialAction icon={<Flight/>} onClick={() => openDialog('createTrip', { replace: true })} tooltipTitle="Trip" tooltipOpen/>
          </SpeedDial>
        </Box>
      )} */}
    </React.Fragment>
  );
};
