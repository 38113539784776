import React from 'react';
import { Autocomplete, AutocompleteProps, TextField, TextFieldProps } from '@mui/material';

import { useDebouncedState } from '@shared/hooks/useDebouncedState';

import { useAddressSearch } from '../hooks/useAddressSearch';


export type SearchAddressAutocompleteProps = Omit<AutocompleteProps<any, false, false, true>, 'isOptionEqualToValue' | 'getOptionLabel' | 'renderInput' | 'options' | 'onInputChange' | 'onChange' | 'loading'> & {
  label?: TextFieldProps['label'];
  TextFieldProps?: TextFieldProps;
  onChange: (option: CVT.MaybeNull<any>) => void;
  hideAdornment?: boolean;
};

export const SearchAddressAutocomplete: React.FC<SearchAddressAutocompleteProps> = React.forwardRef(({ value, defaultValue, label, onChange, hideAdornment, TextFieldProps, ...props }, ref) => {
  const [search, setSearch, debouncedSearch, isDebouncing] = useDebouncedState('');
  const { status, results } = useAddressSearch(debouncedSearch, { enabled: true, suspense: false });

  const inputRef = React.useRef<HTMLInputElement>(null);

  return (
    <Autocomplete
      {...props}
      ref={ref}
      value={value}
      defaultValue={defaultValue}
      getOptionLabel={option => option?.name}
      filterOptions={options => options}
      popupIcon={null}
      renderInput={(props) =>
        <TextField
          {...props}
          {...TextFieldProps}
          InputProps={{
            inputRef,
            ...props.InputProps,
            ...TextFieldProps?.InputProps,
          }}
          InputLabelProps={{
            ...props.InputLabelProps,
            ...TextFieldProps?.InputLabelProps,
            shrink: true,
          }}
          label={label}
        />
      }
      inputValue={search || ''}
      onInputChange={(_, value) => setSearch(value)}
      options={(isDebouncing || status === 'loading') ? [] : results}
      onChange={(e, value) => {
        if (typeof value !== 'string') {
          onChange(value);
          value && inputRef.current?.blur();
        }
      }}
      onBlur={e => {
        if (!value) {
          const option = results[0];
          onChange(option);
        }
        props?.onBlur && props.onBlur(e);
      }}
      loading={isDebouncing || (!!debouncedSearch && status === 'loading')}
      noOptionsText="No locations"
      slotProps={{
        paper: {
          elevation: 1,
        },
      }}
    />
  );
});
